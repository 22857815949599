import React, { useRef , useState } from "react";
import emailjs from "@emailjs/browser";
import toast, { Toaster } from "react-hot-toast";
export default function ContactMe() {
   const form = useRef();
   const {clearData, setClearData} = useState("")

   const sendEmail = (e) => {
     e.preventDefault();

     emailjs
       .sendForm(
         "service_53juq89",
         "template_k5qcmuj",
         form.current,
         "TGmle-wlAYXgoq_pj"
       )
       .then(
         () => {
           
           toast.success("Successfully sent ");
            setClearData("")
         },
         () => {
           
           toast.error("didn't send the message.");
            setClearData("")
         }
       );
   };
  return (
    <section id="Contact" className="contact--section">
      <div>
        <p className="sub--title">Get In Touch</p>
        <h2>Contact Me</h2>
        {/* <p className="text-lg">
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. In, odit.
        </p> */}
      </div>
      <form
        className="contact--form--container"
        ref={form}
        onSubmit={sendEmail}
      >
        <div className="container">
          <label htmlFor="user_name" className="contact--label">
            <span className="text-md">First Name</span>
            <input
              type="text"
              className="contact--input text-md"
              name="user_name"
              id="user_name"
               value={clearData}
               onchange={(e)=>setClearData(e.target.value)}
              required
            />
          </label>
          <label htmlFor="last_name" className="contact--label">
            <span className="text-md">Last Name</span>
            <input
              type="text"
              className="contact--input text-md"
              name="last_name"
              id="last_name"
              required
            />
          </label>
          <label htmlFor="user_email" className="contact--label">
            <span className="text-md">Email</span>
            <input
              type="email"
              className="contact--input text-md"
              name="user_email"
              id="user_email"
              required
            />
          </label>
          <label htmlFor="user_phone" className="contact--label">
            <span className="text-md">phone-number</span>
            <input
              type="number"
              className="contact--input text-md"
              name="user_phone"
              id="user_phone"
              required
            />
          </label>
        </div>

        <label htmlFor="message" className="contact--label">
          <span className="text-md">Message</span>
          <textarea
            className="contact--input text-md"
            id="message"
            rows="8"
            name="message"
            placeholder="Type your message..."
            required
          />
        </label>

        <div>
          <button type="submit" className="btn btn-primary contact--form--btn">
            Send
          </button>
        </div>
      </form>
      <Toaster position="top-right" reverseOrder={false} />
    </section>
  );
}
