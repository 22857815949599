export default function AboutMe() {
  return (
    <section id="AboutMe" className="about--section">
      <div className="about--section--img">
        <p className="section--title" style={{marginBottom:"30px"}}>About</p>
        <img
          src="https://i.ibb.co/ZLvZ9Xf/photo-2022-02-09-10-39-43-removebg-preview-removebg-preview-2.jpg"
          alt="About Me"
        />
      </div>
      <div className="hero--section--content--box about--section--box">
        <div className="hero--section--content">
          <h1 className="skills-section--heading">About Me</h1>
          <p className="hero--section-description">
            I offer multi-faceted experience and education as a Full Stack
            Development Professional with a keen focus on Web Development,
            complex problem solving, process implementation, and collaboration
            skills with strong and talented teams. I am a creative thinker with
            an innovative and strategic vision to how I approach my position
            with the decision-making ability to manage operations, analytics,
            and relationships as needed.
          </p>
          <p className="hero--section-description">
            I'm happy to connect, listen and help. Let's work together and build
            something awesome. Let's turn your idea to an even greater product.
          </p>
        </div>
      </div>
    </section>
  );
}
